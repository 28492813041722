<script setup>
import { defineProps, onMounted, reactive } from "vue";
import mushroom from "cem-primary-api";
import MethodService from "../../service/MethodService";
import VueJsonPretty from "vue-json-pretty";
import Loading from "@/components/LoadingCircle";
import { ref } from "vue";
import { watch } from "vue";
const props = defineProps(["logId"]);
const selectedValueOld = ref([]);
const selectedValueNew = ref([]);
const loading = ref(false);
const logDetails = reactive({
  value: {
    id: undefined,
    t: undefined,
    a: undefined,
    stat: undefined,
    desc: undefined,
    dt: undefined,
    uid: undefined,
    ip: undefined,
    dept: undefined,
    deleted: false,
  },
});
const displayJSON = ref(false);

const getLogDetails = async (id) => {
  try {
    loading.value = true;
    displayJSON.value = false;
    const response = await mushroom.cem_log.findByIdAsync({
      id: id, // required
      fields: "id,t,a,stat,desc,o,n,dt,uid,ip,dept",
    });
    if (response.result) {
      let logAccount = await fn_getUserAccount(response.result);
      let logDepartment = null;
      if (logAccount === undefined) {
        logDepartment = await fn_getUserDepartment(response.result);
      } else logDepartment = await fn_getUserDepartment(logAccount);
      logDepartment.dt = MethodService.formatDateStyle(
        new Date(logDepartment?.dt),
        "datetimeWithSecond"
      );
      if (logDepartment?.o) logDepartment.o = JSON.parse(logDepartment.o);
      if (logDepartment?.n) logDepartment.n = JSON.parse(logDepartment.n);
      if (logDepartment?.o && logDepartment?.n) {
        selectedValueOld.value = compareOldAndNew(
          "oldObj",
          logDepartment.o,
          logDepartment.n
        );
        selectedValueNew.value = compareOldAndNew(
          "newObj",
          logDepartment.o,
          logDepartment.n
        );
      }
      logDetails.value = logDepartment;
      loading.value = false;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    loading.value = false;
  }
};

const fn_getUserAccount = async (data) => {
  try {
    let _data = undefined;
    let response = await mushroom.user.findByIdAsync({
      id: data.uid,
    });
    if (response?.result) {
      let username = response?.result?.account;
      data.username = username;
      _data = data;
    }
    return _data;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const fn_getUserDepartment = async (data) => {
  try {
    let _data = undefined;
    let response = await mushroom.department.findByIdAsync({
      id: data.dept,
    });
    if (response?.result) {
      let department_name = response?.result?.name;
      data.department_name = department_name;
      _data = data;
    }
    return _data;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const compareOldAndNew = (root, oldOb, newOb) => {
  let difKey = [];
  const keyList = new Set([...Object.keys(oldOb), ...Object.keys(newOb)]);
  keyList.forEach((key) => {
    if (typeof oldOb[key] === "object" || typeof newOb[key] === "object") {
      if (!Array.isArray(oldOb[key])) {
        if (oldOb[key] && newOb[key]) {
          const childKey = new Set([
            ...Object.keys(oldOb[key]),
            ...Object.keys(newOb[key]),
          ]);
          childKey.forEach((childKid) => {
            let tmpOld = oldOb[key];
            let tmpNew = newOb[key];
            if (typeof tmpOld[childKid] !== "object") {
              if (tmpOld[childKid] !== tmpNew[childKid]) {
                difKey.push(key + "." + childKid);
              }
            } else {
              if (Array.isArray(tmpOld[childKid])) {
                let oldObjKeyArr = tmpOld[childKid];
                let newObjKeyArr = tmpNew[childKid];
                for (let i = 0; i < oldObjKeyArr.length; i++) {
                  if (oldObjKeyArr[i] !== newObjKeyArr[i]) {
                    difKey.push(key + "." + childKid);
                    break;
                  }
                }
              }
            }
          });
        } else difKey.push(key);
      } else {
        if (oldOb[key] && newOb[key]) {
          let oldObjKeyArr = oldOb[key];
          let newObjKeyArr = newOb[key];
          for (let i = 0; i < oldObjKeyArr.length; i++) {
            if (oldObjKeyArr[i] !== newObjKeyArr[i]) {
              difKey.push(key);
              break;
            }
          }
        } else difKey.push(key);
      }
    } else {
      if (oldOb[key] !== newOb[key]) {
        difKey.push(key);
      }
    }
  });
  return difKey.map((key) => root + "." + key);
};

watch(
  () => props.logId,
  () => {
    getLogDetails(props.logId);
    selectedValueOld.value = [];
    selectedValueNew.value = [];
  }
);

onMounted(() => {
  getLogDetails(props.logId);
});
</script>
<template>
  <hr />
  <div v-if="loading">
    <Loading />
  </div>
  <div v-else>
    <div class="mb-2">
      <strong class="me-2">{{ $t("t-exe-datetime") }}: </strong>
      {{ logDetails.value.dt }}
    </div>
    <b-row class="mb-2">
      <b-col md="3">
        <strong class="me-2">{{ $t("t-log-type") }}: </strong
        >{{ logDetails.value.t }}
      </b-col>
      <b-col md="4">
        <strong class="me-2">{{ $t("t-action-type") }}: </strong
        >{{ logDetails.value.a }}
      </b-col>
      <b-col md="5">
        <strong class="me-2">{{ $t("t-status") }}: </strong
        ><span
          v-if="logDetails.value.stat === 0"
          class="badge badge-soft-success text-uppercase"
          >{{ $t("t_success") }}</span
        >
        <span v-else class="badge badge-soft-danger text-uppercase">{{
          $t("t_error")
        }}</span>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col md="3">
        <strong class="me-2">{{ $t("t-username") }}: </strong
        >{{ logDetails.value.username }}
      </b-col>
      <b-col md="4">
        <strong class="me-2">{{ $t("t-ip") }}: </strong
        >{{ logDetails.value.ip }}
      </b-col>
      <b-col md="5">
        <strong class="me-2">{{ $t("t-department") }}: </strong
        >{{ logDetails.value.department_name }}
      </b-col>
    </b-row>
    <div class="mb-2">
      <strong class="me-2">{{ $t("t-description") }}: </strong
      >{{ logDetails.value.desc }}
    </div>

    <div class="mb-2">
      <div class="mb-2">
        <el-link
          :underline="false"
          type="primary"
          @click="displayJSON = !displayJSON"
        >
          {{ $t("t_view_change_json") }}
          <div class="d-flex align-item-center">
            <i
              class="ri-arrow-down-s-line"
              style="font-size: 16px"
              v-if="displayJSON"
            ></i>
            <i class="ri-arrow-right-s-line" style="font-size: 16px" v-else></i>
          </div>
        </el-link>
      </div>
      <div class="row" v-if="displayJSON">
        <div
          :class="logDetails.value.n ? 'col-lg-6' : 'col-lg-12'"
          v-if="logDetails.value.o"
        >
          <strong class="me-2"
            >{{
              logDetails.value.n
                ? $t("t-before-update-record")
                : $t("t-deleted-record")
            }}
          </strong>
          <div class="json-container mt-2">
            <vue-json-pretty
              class="oldObject"
              :data="logDetails.value.o"
              :deep="3"
              selectable-type="multiple"
              :show-line-numbers="true"
              :show-length="false"
              :height="300"
              :virtual="true"
              :showLineNumber="true"
              root-path="oldObj"
              v-model:selected-value="selectedValueOld"
              :highlightSelectedNode="true"
              :selectOnClickNode="true"
            />
          </div>
        </div>
        <div
          :class="logDetails.value.o ? 'col-lg-6' : 'col-lg-12'"
          v-if="logDetails.value.n"
        >
          <strong class="me-2"
            >{{
              logDetails.value.o
                ? $t("t-after-update-record")
                : $t("t-created-record")
            }}
          </strong>
          <div class="json-container mt-2">
            <vue-json-pretty
              :data="logDetails.value.n"
              :deep="3"
              selectable-type="multiple"
              :show-line-numbers="true"
              :show-length="false"
              :height="300"
              :virtual="true"
              :showLineNumber="true"
              root-path="newObj"
              v-model:selected-value="selectedValueNew"
              :highlightSelectedNode="true"
              :selectOnClickNode="true"
              theme="light"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
:deep .oldObject {
  .vjs-tree-list {
    .vjs-tree-list-holder-inner {
      .vjs-tree-node {
        .vjs-tree-node {
          &.is-highlight {
            background-color: rgb(233, 111, 111);
          }
        }
      }
    }
  }
}
</style>
