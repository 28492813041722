<script setup>
// layout -> bắt buộc
import Layout from "../../layouts/main.vue";
import mushroom from "cem-primary-api";
import { onMounted, reactive, ref, watch } from "vue";
import MethodService from "../../service/MethodService";
import LangService from "@/service/LanguageService";
import DataForm from "./dataFormLog";
import { langWeb } from "../../stores/lang";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import toastr from "toastr";
import logDetail from "./log-detail.vue";
const route = useRoute();
const router = useRouter();
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const table = ref(null);
const loading = ref(false);
const dataTables = reactive({ value: [] });
const listUser = ref([]);
const listDepartment = ref([]);
const logDetails = reactive({ value: {} });
const visibleDialogLogDetails = ref(false);
const roleUser = ref("");
// const selectedTimeRange = ref([]);
const statusLabel = ref([
  {
    value: null,
    label: LangService[lang_web.showLang.toLocaleUpperCase()]?.t_all ?? "t_all",
  },
  {
    value: "0",
    label:
      LangService[lang_web.showLang.toLocaleUpperCase()]?.t_success ??
      "t_success",
  },
  {
    value: "1",
    label:
      LangService[lang_web.showLang.toLocaleUpperCase()]?.t_error ?? "t_error",
  },
]);

const logLabel = ["Account", "Department", "Probe", "Menu"];

const actionLabel = {
  Account: [
    "Create Account",
    "Update Account",
    "Delete Account",
    "Update Profile",
  ],
  Department: ["Create Department", "Update Department", "Delete Department"],
  Probe: ["Create Probe", "Update Probe", "Delete Probe"],
  Menu: ["Create Menu", "Update Menu", "Delete Menu"],
};

const actionList = ref(null);

const fn_LayDanhSachLog = async (clickFind) => {
  loading.value = true;
  if (!clickFind && tableRules.dataSearch.value["t"])
    handleChangeTypeSelect(true);
  clickFind = clickFind === undefined ? true : clickFind;
  if (!clickFind && tableRules.dataSearch.value["dt"]) {
    let timeRange = tableRules.dataSearch.value["dt"];
    let startDate = new Date(timeRange[0]);
    let endDate = new Date(timeRange[1]);
    endDate = MethodService.addDate(endDate, "days", -1);
    tableRules.dataSearch.value["dt"] = [startDate, endDate];
  }
  if (tableRules.sort) {
    let prop =
      tableRules.sort[0] === "-" ? tableRules.sort.slice(1) : tableRules.sort;
    let order = tableRules.sort[0] === "-" ? "descending" : "ascending";
    tableRules.defaultSort = [prop, order];
  }
  let dataFilter = {
    filters: tableRules.filters,
    fields: tableRules.fields,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  if (roleUser.value.includes("Admin")) {
    if (dataFilter.filters === undefined || dataFilter.filters === "")
      dataFilter.filters = "deleted!=true";
    else dataFilter.filters += "&deleted!=true";
  }
  let query = {
    filters: tableRules.filters,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
    showFormSearch: tableRules.showFormSearch,
  };
  router.replace({
    name: "DanhSachLog",
    query: query,
  });
  try {
    const response = await mushroom.cem_log.listAsync(dataFilter);

    if (response.result) {
      let dataAccount = await fn_getListUser(response.result);
      let dataWithDepartment = await fn_getListDepartment(dataAccount);
      dataWithDepartment.forEach((item) => {
        let stdDatetime = new Date(item.dt);
        item.dt = MethodService.formatDateStyle(
          stdDatetime,
          "datetimeWithSecond"
        );
      });
      dataTables.value = [...dataWithDepartment];
      tableRules.total = response.meta.total;
      tableRules.page =
        parseInt(response.meta.offset / response.meta.limit) + 1;
      loading.value = false;
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
    MethodService.showError(e.code);
  } finally {
    loading.value = false;
  }
};

const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
  let query = Object.assign({}, route.query);
  query.showFormSearch = tableRules.showFormSearch;
  router.push({
    name: "DanhSachLog",
    query: query,
  });
};

const fn_handle = (type, scope, row) => {
  if (type == "view") {
    logDetails.value = row;
    visibleDialogLogDetails.value = true;
  } else {
    Swal.fire({
      title: LangService[lang.value]?.t_warning ?? "t_warning",
      text: LangService[lang.value]?.t_data_had_delete ?? "t_data_had_delete",
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: LangService[lang.value]?.swal_accept ?? "swal_accept",
      cancelButtonText: LangService[lang.value]?.swal_cancel ?? "swal_cancel",
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await mushroom.cem_log.partialUpdateAsync({
            id: row.id, // required
            deleted: true,
          });
          if (response.result) {
            toastr.success(
              LangService[lang.value]?.toastr_delete_success ??
                "toastr_delete_success"
            );
            fn_LayDanhSachLog();
          }
        } catch (e) {
          console.error("Có lỗi: %o", e);
        }
      }
    });
  }
};

const fn_TimKiem = () => {
  if (
    !tableRules.dataSearch.value.a ||
    tableRules.dataSearch.value.a.length === 0
  )
    tableRules.dataSearch.value.a = null;
  if (
    !tableRules.dataSearch.value.t ||
    tableRules.dataSearch.value.t.length === 0
  )
    tableRules.dataSearch.value.t = null;
  tableRules.filters = MethodService.filterTable(
    JSON.stringify(tableRules.dataSearch)
  );
  tableRules.offset = 0;
  tableRules.sort = "";
  fn_LayDanhSachLog(true);
};

const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  fn_LayDanhSachLog();
};

const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};

const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};

const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};

const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  fn_LayDanhSachLog();
};
const fn_tableSortChange = async (column) => {
  MethodService.tableSortChange(column, tableRules);
  loading.value = true;
  await fn_LayDanhSachLog();
};

const fn_hashUrl = () => {
  MethodService.hashUrl(route.query, tableRules);
};

const fn_getListUser = async (data) => {
  try {
    let _data = undefined;
    let userArr = data.map((item) =>
      mushroom.user.findByIdAsync({
        id: item.uid,
      })
    );
    await Promise.all(userArr).then((dataUsers) => {
      data.forEach((item) => {
        dataUsers.forEach((itemDataUser) => {
          if (item.uid === itemDataUser?.result?.id) {
            item.user = itemDataUser.result.account;
          }
        });
      });
      _data = data;
    });
    return _data;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

const fn_getListDepartment = async (data) => {
  let _data = undefined;
  let departmentArr = data.map((item) =>
    mushroom.department.findByIdAsync({
      id: item.dept, // required
    })
  );

  await Promise.all(departmentArr).then((dataDepartments) => {
    data.forEach((item) => {
      dataDepartments.forEach((dataDepartment) => {
        if (item.dept === dataDepartment?.result?.id) {
          item.department_name = dataDepartment.result.name;
        }
      });
    });
    _data = data;
  });
  return _data;
};

// Lấy dữ liệu phục vụ tìm kiếm
const fn_getListDepartmentForSearching = async () => {
  try {
    const response = await mushroom.department.getAllAsync();
    if (response.result) {
      response.result.forEach((item) => {
        listDepartment.value.push({
          label: item.name,
          value: item.id,
        });
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const fn_getListUserForSearching = async () => {
  try {
    const response = await mushroom.user.getAllAsync();
    if (response.result) {
      response.result.forEach((item) => {
        listUser.value.push({
          label: item.account,
          value: item.id,
        });
      });
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};

//
const handleChangeTypeSelect = () => {
  let selectTypeList = null;
  if (typeof tableRules.dataSearch.value["t"] === "string") {
    selectTypeList = tableRules.dataSearch.value["t"].split(",");
    if (tableRules.dataSearch.value["a"])
      tableRules.dataSearch.value["a"] =
        tableRules.dataSearch.value["a"].split(",");
    tableRules.dataSearch.value["t"] = selectTypeList;
  } else selectTypeList = tableRules.dataSearch.value["t"];
  let tmpList = [];
  selectTypeList.forEach((item) => {
    tmpList.push(...actionLabel[item]);
  });
  actionList.value = tmpList;
};

const handleChangeTimeRange = (value) => {
  if (value && value.length > 0) {
    let startDate = new Date(value[0]);
    let endDate = new Date(value[1]);
    endDate.setHours(23, 59, 59, 999);
    tableRules.dataSearch.value["dt"] = [startDate, endDate];
  } else tableRules.dataSearch.value["dt"] = null;
};

const getRole = async () => {
  const response = await mushroom.$auth.meAsync();
  roleUser.value = response.result.roles;
};

watch(
  () => lang_web.showLang,
  (newLang) => {
    lang.value = newLang.toLocaleUpperCase();
    statusLabel.value = [
      {
        value: null,
        label:
          LangService[lang_web.showLang.toLocaleUpperCase()]?.t_all ?? "t_all",
      },
      {
        value: "0",
        label:
          LangService[lang_web.showLang.toLocaleUpperCase()]?.t_success ??
          "t_success",
      },
      {
        value: "1",
        label:
          LangService[lang_web.showLang.toLocaleUpperCase()]?.t_error ??
          "t_error",
      },
    ];
  }
);
onMounted(() => {
  fn_hashUrl();
  getRole();
  fn_getListUserForSearching();
  fn_getListDepartmentForSearching();
  fn_LayDanhSachLog();
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 100vh">
      <div class="card-header justify-content-between" style="display: flex">
        <h3 class="text-uppercase mb-0">{{ $t("t-log-list") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border mr-2"
              @click="fn_showFormTimKiem"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-if="tableRules.showFormSearch"
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  label-position="top"
                  ref="ruleFormRef"
                  @submit.prevent
                  :rules="rulesForm"
                  model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-log-type')">
                        <el-select
                          v-model="tableRules.dataSearch.value['t']"
                          :placeholder="$t('t-place-log-type')"
                          placement="bottom"
                          multiple
                          @change="handleChangeTypeSelect(false)"
                          clearable
                        >
                          <el-option
                            v-for="item in logLabel"
                            :key="item"
                            :label="item"
                            :value="item"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="3">
                      <el-form-item :label="$t('t-username')">
                        <el-select
                          filterable
                          v-model="tableRules.dataSearch.value['uid']"
                          :placeholder="$t('t-place-username')"
                          :no-match-text="$t('t-match-data')"
                          clearable
                        >
                          <el-option
                            v-for="item in listUser"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="3">
                      <el-form-item :label="$t('t-ip')">
                        <el-input
                          :placeholder="$t('t-place-ip')"
                          v-model="tableRules.dataSearch.value['ip']"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-action-type')">
                        <el-select
                          :placeholder="$t('t-place-action-type')"
                          placement="bottom"
                          multiple
                          v-model="tableRules.dataSearch.value['a']"
                          collapse-tags
                          collapse-tags-tooltip
                          :max-collapse-tags="3"
                          :no-data-text="$t('t-not-choose-log-type-yet')"
                          clearable
                        >
                          <el-option
                            v-for="item in actionList"
                            :key="item.valueOf(value)"
                            :value="item.valueOf(value)"
                            :label="item.label"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="3">
                      <el-form-item :label="$t('t-department')">
                        <el-select
                          filterable
                          v-model="tableRules.dataSearch.value['dept']"
                          :placeholder="$t('t-place-departments')"
                          :no-match-text="$t('t-match-data')"
                          clearable
                        >
                          <el-option
                            v-for="item in listDepartment"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="3">
                      <el-form-item :label="$t('t-status')">
                        <el-select
                          :placeholder="$t('t-place-status')"
                          v-model="tableRules.dataSearch.value['stat']"
                          clearable
                          placement="bottom"
                        >
                          <el-option
                            v-for="item in statusLabel"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-time-range')">
                        <div class="demo-date-picker">
                          <div class="block">
                            <el-date-picker
                              type="daterange"
                              v-model="tableRules.dataSearch.value['dt']"
                              :range-separator="$t('t-to')"
                              :start-placeholder="$t('t-start-date')"
                              :end-placeholder="$t('t-end-date')"
                              @change="handleChangeTimeRange"
                            />
                          </div>
                        </div>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item :label="$t('t-description')">
                        <el-input
                          :placeholder="$t('t-place-description')"
                          v-model="tableRules.dataSearch.value['desc']"
                          clearable
                        ></el-input>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
            <div class="mb-3" style="float: right">
              <el-pagination
                small
                v-model:currentPage="tableRules.page"
                v-model:pageSize="tableRules.limit"
                :page-sizes="tableRules.lengthMenu"
                :total="tableRules.total"
                @size-change="fn_tableSizeChange"
                @currentChange="fn_tableCurentChange"
                @prev-click="fn_tablePrevClick"
                @next-click="fn_tableNextClick"
                background
                layout="total,sizes,prev,pager,next,jumper"
              />
            </div>
            <el-table
              size="small"
              style="width: 100%"
              min-height="500"
              ref="table"
              v-loading="loading"
              :empty-text="$t('t-no-data')"
              :data="dataTables.value"
              @sort-change="fn_tableSortChange"
              :default-sort="{
                prop: tableRules.defaultSort[0],
                order: tableRules.defaultSort[1],
              }"
            >
              <el-table-column
                prop="index"
                :label="$t('t-stt')"
                width="70"
                align="center"
              >
                <template #default="scope">
                  <div class="text-center">
                    {{ tableRules.offset + scope.$index + 1 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="t"
                :label="$t('t-log-type')"
                min-width="120"
                align="center"
                sortable="custom"
              />
              <el-table-column
                prop="a"
                :label="$t('t-action-type')"
                min-width="200"
                align="center"
              />
              <el-table-column
                prop="user"
                :label="$t('t-username')"
                min-width="150"
                align="center"
              />
              <el-table-column
                prop="ip"
                :label="$t('t-ip')"
                min-width="150"
                align="center"
              />
              <el-table-column
                prop="department_name"
                :label="$t('t-department')"
                min-width="200"
                align="center"
              />
              <el-table-column
                prop="dt"
                :label="$t('t-exe-datetime')"
                min-width="200"
                align="center"
                sortable="custom"
              >
                <template #default="scope">
                  <span>{{ scope.row.dt }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="stat"
                :label="$t('t-status')"
                min-width="110"
                align="center"
              >
                <template #default="scope">
                  <span
                    v-if="scope.row.stat === 0"
                    class="badge badge-soft-success text-uppercase"
                    >{{ $t("t_success") }}</span
                  >
                  <span v-else class="badge badge-soft-danger text-uppercase">{{
                    $t("t_error")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="desc"
                :label="$t('t-description')"
                min-width="300"
                align="center"
              />

              <el-table-column
                :label="$t('t-action')"
                align="center"
                width="100"
              >
                <template #default="scope">
                  <div class="btn-group-thao-tac">
                    <el-tooltip
                      class="box-item"
                      effect="light"
                      :content="$t('t-view')"
                      placement="top"
                      :enterable="false"
                    >
                      <button
                        type="button"
                        title="read"
                        class="btn btn-soft-success waves-effect waves-light btn-sm"
                        @click="fn_handle('view', scope.$index, scope.row)"
                      >
                        <i class="ri-eye-line"></i>
                      </button>
                    </el-tooltip>
                    <el-tooltip
                      class="box-item"
                      effect="light"
                      :content="$t('t-delete')"
                      placement="top"
                      :enterable="false"
                    >
                      <button
                        type="button"
                        title="read"
                        class="btn btn-soft-danger waves-effect waves-light btn-sm"
                        @click="fn_handle('delete', scope.$index, scope.row)"
                        v-if="roleUser.includes('Admin')"
                      >
                        <i class="ri-delete-bin-6-line"></i>
                      </button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div class="mt-3" style="float: right">
              <el-pagination
                small
                v-model:currentPage="tableRules.page"
                v-model:pageSize="tableRules.limit"
                :page-sizes="tableRules.lengthMenu"
                :total="tableRules.total"
                @size-change="fn_tableSizeChange"
                @currentChange="fn_tableCurentChange"
                @prev-click="fn_tablePrevClick"
                @next-click="fn_tableNextClick"
                background
                layout="total,sizes,prev,pager,next,jumper"
              />
            </div>
        </div>
      </div>
      <el-dialog
        v-model="visibleDialogLogDetails"
        :title="$t('t-log-have-id', { msg :logDetails.value.id })"
        :close-on-click-modal="false"
        center
        width="70%"
      >
        <log-detail :logId="logDetails.value.id"> </log-detail>
      </el-dialog>
    </div>
  </Layout>
</template>
<link type="scss" src="./log.scss" />
